/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useFileUpload } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { saveImagesData, fetchImagesData } from './../../action';
import Cookies from 'js-cookie'
import '../dashboard/dashboard.css';
import Button from '@mui/material/Button';

const InputFile = (props) => {
  const [imageFile, setImageFile] = useState('');
  const [hasImportFile, setHasImportFile] = useState(false);
  const { uploadFile } = useFileUpload();
  const [isEnabled, setIsEnabled] = useState(false);
  const dispatch = useDispatch();
  const [showFileFormateError, setShowFileFormateError] = useState(null);

  /**
   * Get the updated state
   */
  const ImageDataState = useSelector((state) => state.ImageData);

  const fileChangeHandler = (event) => {
    let regex = new RegExp(/[^\s]+(.*?).(tiff|svs|TIFF|SVS)$/);
    if (event.target.files[0]) {
      if (regex.test(event.target.files[0].name) === true) {
        setShowFileFormateError(!true);
    }
    else {
        setShowFileFormateError(!false);
    };
    };
    if (event.target.files) {
      const inputFileArray = Array.from(event.target.files);
      const fileNameArray = [];
      inputFileArray.forEach(data => {
        fileNameArray.push(data.name);
      });
      let filename = JSON.stringify(fileNameArray.toString()).replace(/^"|"$/g, '');
      document.getElementById("file-upload-filename").innerHTML=filename;
    }
    setImageFile(event.target.files);
    setHasImportFile(true);

  };

  const fileSubmitHandler = () => {
    const imageSize = props.imageSizeValue;
    const imageHeight = Number(imageSize.split(',')[0]);
    const imageWidth = Number(imageSize.split(',')[1]);
    const azureData = {
      image_tag: props.tags,
      image_desc: props.imageDesc,
      imageFile: imageFile,
      "height": imageHeight,
      "width": imageWidth,
      "tissue_type": props.tissueType
    }
    if (hasImportFile) {
      setIsEnabled(true);
      uploadFile(azureData);
      props.handleCallBack(true)
    } else {
      return false;
    }

    return false;
  };

  useEffect(() => {
    if(isEnabled){
      if (ImageDataState && ImageDataState.status === 'success') {
        const user_id = Cookies.get('user_id');
        dispatch(fetchImagesData(user_id));
        props.handleCallBack(false)
        toast.success("Successfully uploaded the image");
        dispatch(saveImagesData({}, true));
        setIsEnabled(false);
        // props.reSetField();
        let filename = '';
        document.getElementById("file-upload-filename").innerHTML=filename;
        document.getElementById("outlined-disabled").value = "";
        document.getElementById("outlined-multiline-static").value = "";
      } else {
        props.handleCallBack(false)
        toast.error("Something went wrong");
        dispatch(saveImagesData({}, true));
        setIsEnabled(false);
        // props.reSetField();
        let filename = '';
        document.getElementById("file-upload-filename").innerHTML=filename;
        document.getElementById("outlined-disabled").value = "";
        document.getElementById("outlined-multiline-static").value = "";
      }
    }
}, [ImageDataState]);

  return (
    <>
    <div className='wrp10'>
      
      <input id="file-upload" className='upinp' type="file" multiple="multiple" onChange={fileChangeHandler} />
      <label htmlFor="file-2" className='uplbl'>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
        <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" fill="#084d7e"></path></svg>
       <span className='choosefile'>Choose a file…</span>
       </label>
       <div id="file-upload-filename"></div>
       {showFileFormateError && <div id="file-filename">Please select the valid file formate (SVS OR TIFF).</div>}
    </div>
   
    <Button variant="contained" className='btnupload' disabled={showFileFormateError} sx={{
        backgroundColor: '#788f9d',
        '&:hover': {
          backgroundColor: '##386a89',
        },
      }}  onClick={fileSubmitHandler}>Upload!</Button>
    </>
  );
};

export default InputFile;