// UI components
import { Box, Grid } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from '@mui/material/MenuItem';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';

// 3rd party libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect, { components } from "react-select";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'
import OpenSeadragon from 'openseadragon';

// Custom components
import useImageEngine from '../../hooks/useImageEngine.js'; // TODO: may not need to be used depending on ImageViewer.jsx changes later
import { ImageViewer, UploadForm } from './components/index.js'; // TODO: same as above
import { getAlgorithms, fetchImagesData, processImageApi, getProcessedImageData } from '../../action/index.js';
import AppLoader from './appLoader/AppLoader.js'
import imgview from "../dashboard/imgview.png";

const Dashboard = () => {
    /* ----------------------------------- VARS ----------------------------------- */

    // TODO: selections and dropdowns may be able to be merged

    const dispatchHook = useDispatch(); // for calling api actions
    const [expandedAccordionMenu, setExpandedAccordionMenu] = useState(null); 

    /* upload vars */
    const [allowImageUpload, setAllowImageUpload] = useState(true);
    const [imageUploading, setImageUploading] = useState(false);
    const [isLoader, setLoader] = useState(false); // TODO: may not be needed depnding on removal of algo load code
    
    /* unprocessed image vars */
    const [selectedImageId, setSelectedImageId] = useState(''); // also used for processed vars
    const imageDropdownList = useSelector((state) => { // also used for processed vars
        return state.fetchImageData.data;
    })
    const [selectedUnprocessedImage, setSelectedUnprocessedImage] = useState([]);
    const [unprocessedImageDescription, setUnprocessedImageDescription] = useState('');
    const [unprocessedImageTag, setUnprocessedImageTag] = useState('');
    const [unprocessedImageSize, setUnprocessedImageSize] = useState({
        width: '',
        height: ''
    });
    const [viewer, setViewer] = useState(''); // TODO: may be removed depending on ImageViewer.jsx changes later
    const [imageLoading, setImageLoading] = useState(false); // TODO: same as above

    /* inference vars */
    const [modalityRadioButton, setModalityRadioButton] = useState('vIHC');
    const [multipleStainsSelected, setMultipleStainsSelected] = useState(true); // TODO: inverse function to name
    const [dropdownDisplayedStainList, setDropdownDisplayedStainList] = useState('');
    const [selectedStainList, setSelectedStainList] = useState([]);
    const stainColourOptions = [
        { value: "er", label: "er" },
        { value: "pr", label: "pr" },
        { value: "her2", label: "her2" }
    ];
    const stainOptionComponent = (props) => { // TODO: might need to rename components.stainOptionComponent back to components.Option
        return (
          <div>
            <components.stainOptionComponent {...props}> 
              <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{" "}
              <label>{props.label}</label>
            </components.stainOptionComponent>
          </div>
        );
    };
    const [selectedImageToProcess, setSelectedImageToProcess] = useState('');
    const [selectedImageToProcessName, setSelectedImageToProcessName] = useState([]);
    const [dropdownDisplayedAlgorithmName, setDropdownDisplayedAlgorithmName] = useState('');
    const [selectedAlgorithm, setSelectedAlgorithm] = useState([]);
    const algorithmResponse = useSelector((state) => state.AlgoData); // AlgoData retrieved from store.js
    const unusedAlgoVar = useSelector((state) => state.algoList); // TODO: previously algoListData, seems unused
                                                                  // algoList retrieved from store.js
    const [unusedAlgoVar2, setUnusedAlgoVar2] = useState([]); // TODO: previously algoData, appears in code but isn't set to anything(?)

    /* processed image vars */
    const [selectedProcessedImage, setSelectedProcessedImage] = useState('');
    const [selectedProcessedImageName, setSelectedProcessedImageName] = useState([]);
    const processedImageData = useSelector((state) => state.processedImageData.data);
    const dropdownDisplayedProcessedImages = processedImageData; // TODO: probably should be changed to a handleUnprocessedImageSelection function 

    /* ----------------------------- useEffect HOOKS ----------------------------- */

    useEffect(() => { // initial useEffect for setting initial state and returning required data
        setAllowImageUpload(false); // TODO: does this need to be here?
                                    // can't it just be set to default on start?
                                    // or does it get affected by runtime and always needs to be set false unless uploading
        setLoader(false);
        const userId = Cookies.get('user_id');
        dispatchHook(fetchImagesData(userId)); // gets the list of unprocessed images 
        dispatchHook(getProcessedImageData()); // gets the list of processed images
        dispatchHook(getAlgorithms()); // gets the list of available algorithms
    }, []);

    /*useEffect(() => { // viewer initialization and options
        viewer && viewer.destroy();
        setViewer(
            OpenSeadragon({ // TODO: comment more on these
                id: "diz_openSeaDragon",
                prefixUrl: "openseadragon-images/",
                minZoomImageRatio: 1, // stops zoom out more than the preview
                minPixelRatio: 5, // lower quality to save data for the preview
                immediateRender: true,
                maxZoomLevel: 1, // stops user from zooming, may be unintented functionality, can set to n for nx zoom
                gestureSettingsMouse: { dragToPan: false }, //if zoom is not needed
                defaultZoomLevel: 1,
                visibilityRatio: 1,
                crossOriginPolicy: 'Anonymous',
                ajaxWithCredentials: true,
                sequenceMode: true,
                showHomeControl: false,
                showZoomControl: false,
                showNavigationControl: false,
                showSequenceControl: false,
            })
        );
    }, []); // TODO: is constant, might want to change to on viewer change*/

    /* upload useEffetcs */

    /* unprocessed image useEffetcs */
    useEffect(() => { // sets the default selected image ID when none is selected, used for processed image dropdowns as well
        if(imageDropdownList !== undefined && imageDropdownList.length > 0 && selectedImageId === '') {
            setSelectedImageId(imageDropdownList[0].id);
        }
    }, [imageDropdownList]);

    /* inference useEffetcs */
    useEffect(() => { // algorithm on image success check
        if (imageUploading) {
            if (algorithmResponse && algorithmResponse.status === 'success') {
                toast.success("Successfully processed image");
                dispatchHook(processImageApi({}, true)); // creates new processed image on backend
                setImageUploading(false);
                setLoader(false);
                dispatchHook(getProcessedImageData()); // gets the new list of processed images
            } else {
                toast.error("Something went wrong");
                dispatchHook(processImageApi({}, true)); // will respond with failed status? maybe shouldn't be done?
                setImageUploading(false);
                setLoader(false);
            }
        }
    }, [algorithmResponse]);

    useEffect(() => { // TODO: may remove, has to do with algo data but 'unusedAlgoVar' useEffect is never used
        if(unusedAlgoVar && unusedAlgoVar.status === 'success' && unusedAlgoVar.data){
            setUnusedAlgoVar2(unusedAlgoVar.data);
            dispatchHook(getAlgorithms(true)); // gets the list of algorithms
            setLoader(false);
            setAllowImageUpload(true);
        }
    }, [unusedAlgoVar]); // never used

    /* processed image useEffetcs */

    /* -------------------------------- FUNCTIONS -------------------------------- */

    /* upload functions */
    const handleCallBack = (data) => { // TODO: may be removed, see var notes
        setLoader(data);
    };

    /* unprocessed image functions */
    /** 
    * @function handleUnprocessedImageSelection
    * @param {string} event
    */
    const handleUnprocessedImageSelection = (event: SelectChangeEvent) => { // deals with selection of unprocessed images in Uploaded Images
        setImageLoading(true); // TODO: might remove, see var notes
        const uploadedFieldData = event.target.value;
        setUnprocessedImageDescription(uploadedFieldData.image_desc);
        setUnprocessedImageTag(uploadedFieldData.image_tag);
        setUnprocessedImageSize({
            height: uploadedFieldData && uploadedFieldData.meta ? uploadedFieldData.meta.height : '',
            width: uploadedFieldData && uploadedFieldData.meta ? uploadedFieldData.meta.width : ''
        })
        setSelectedUnprocessedImage([event.target.value.file_name]);
        console.log(uploadedFieldData);
        // setSelectedProcessedImageName([event.target.value.file_name]); // revert last commit changes for unprocessed images in viewer
        // setSelectedProcessedImage(event.target.value); 
        const dk = event.target.value;

        const imageUrl = `https://${window.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net${dk.file_location}` // TODO: might remove everything below depending on ImageViewer.jsx changes later
        if (viewer) {
            window.GeoTIFFTileSource(OpenSeadragon);
            viewer.addHandler('tile-drawn', () => {
                setImageLoading(false);
              });
            let tiffTileSources = OpenSeadragon.GeoTIFFTileSource.getAllTileSources(imageUrl, { logLatency: true });
            tiffTileSources.then(ts => viewer.open(ts));
        }
    };

    /* inference functions */
    /** 
    * @function handleModalitySelection 
    * @param {string} e
    */
    const handleModalitySelection = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setModalityRadioButton(value);
        }
    };

    /** 
    * @function handleStainList 
    * @param {object} dropdownDisplayedStainList
    */
    const handleStainList = (dropdownDisplayedStainList) => {
        if (dropdownDisplayedStainList) {
            setDropdownDisplayedStainList(dropdownDisplayedStainList);
            const newArray = []
            dropdownDisplayedStainList.map((data) => {
                newArray.push(data.value);
            })
            if (newArray && newArray.length > 1) { // TODO: swap these
                setMultipleStainsSelected(false)
            } else if (newArray && newArray.length < 2) {
                setMultipleStainsSelected(true)
                if (modalityRadioButton === 'vMultiplex') setModalityRadioButton('vIHC'); // potentially revert
            }
            setSelectedStainList(newArray);
        }
    };

    /** 
    * @function handleStainSelection 
    * @param {string} e
    */
    const handleStainSelection = (e) => { // TODO: unused, check if needed
        const { value, checked } = e.target;
        if (checked) {
            setSelectedStainList([...selectedStainList, value])
        } else {
            setSelectedStainList(selectedStainList.filter((e) => e !== value))
        }
    };

    /** 
    * @function handleImageToProcessSelection 
    * @param {string} event
    */
    const handleImageToProcessSelection = (event: SelectChangeEvent) => {
        setSelectedImageToProcess(event.target.value);
        setSelectedImageToProcessName([event.target.value.file_name]);
    };

    /** 
    * @function handleAlgorithmSelection 
    * @param {string} e
    */
    const handleAlgorithmSelection = (e) => {
        setDropdownDisplayedAlgorithmName(e.target.value.algo_name);
        setSelectedAlgorithm(e.target.value);
    };

    /** 
    * @function handleInferenceUpload // TODO: may need to be renamed back to handleUpload
    * @param {string} item
    */
   
    const handleInferenceUpload = () => { // handles the data attactched to a newly processed image, might rename
        const user_id = Cookies.get('user_id');
        const org_id = Cookies.get('org_id');
        const algo_id = selectedAlgorithm.id;
        const user_upload_id = selectedImageToProcess.id;
        const sample_image_location = selectedImageToProcess.file_location;
        const processed = false
        const sample_name = selectedImageToProcess.file_name;
        const description = 'description';
        const file_format = "image/jpeg";
        const file_uri = selectedImageToProcess.file_uri;
        const stain_list = selectedStainList.toString();
        const v_multiplex = (modalityRadioButton === 'vMultiplex' && multipleStainsSelected === false) ? true : false;
        const v_ihc = modalityRadioButton === 'vIHC' || (modalityRadioButton === 'vMultiplex' && multipleStainsSelected) ? true : false;
        const flow = modalityRadioButton === 'vFlow' ? true : false;

        const processImageData = {
            user_id: user_id,
            organization_id: org_id,
            algo_id: algo_id,
            user_upload_id: user_upload_id,
            sample_image_location: sample_image_location,
            processed: processed,
            sample_name: sample_name,
            description: description,
            file_format: file_format,
            file_uri: file_uri,
            stain_list: stain_list,
            v_multiplex: v_multiplex,
            v_ihc: v_ihc,
            flow: flow
        }
        dispatchHook(processImageApi(processImageData)) // sends the data to the backend
        setDropdownDisplayedStainList(''); // resets the dropdown selections
        setSelectedImageToProcess('');
        setSelectedImageToProcessName('');
        setDropdownDisplayedAlgorithmName(''); 
        setImageUploading(true);
        setLoader(true);
    }

    /* processed image functions */
    /** 
    * @function handleProcessedImageSelection
    * @param {object} item
    */

    const handleProcessedImageSelection = (item) => {
        setSelectedProcessedImageName([item.target.value.sample_name]);
        setSelectedProcessedImage(item.target.value);
    };

    /* ----------------------------------- RENDER ----------------------------------- */
    return ( // unchanged 
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} >
                <Grid item xs={4}>
                    {isLoader && <AppLoader allowImageUpload={allowImageUpload}/>}
                    <Accordion
                        expanded={expandedAccordionMenu === 'panel1'}
                        onChange={(e, expanded) => setExpandedAccordionMenu(expanded ? 'panel1' : false)}
                    >
                        <AccordionSummary className='accbg'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='headcss'>
                                <CloudUploadOutlinedIcon className='icon' />
                                Upload Image
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UploadForm handleCallBack={handleCallBack} unusedAlgoVar2={unusedAlgoVar2} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccordionMenu === 'panel2'}
                        onChange={(e, expanded) => setExpandedAccordionMenu(expanded ? 'panel2' : false)}
                    >
                        <AccordionSummary className='accbg'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-uploaded-header"
                        >

                            <Typography className='headcss'>
                                <InsertPhotoOutlinedIcon className='icon' />
                                Uploaded Images
                            </Typography>
                        </AccordionSummary>
                        <div>
                            <AccordionDetails className='dspflx'>

                                <Box component="fieldset" className='fieldset'
                            
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Box className='legend' component="legend">Uploaded Images</Box>
                                    <div className='wrapsec2'>
                                        <Typography variant='h6' className='headtxtcss fntb14'>
                                            Image :
                                        </Typography>
                                        <FormControl className='selectbox'>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={selectedUnprocessedImage}
                                                onChange={(data) => handleUnprocessedImageSelection(data)}
                                                className='wh100'
                                                displayEmpty
                                                renderValue={(showPlaceholder) => {
                                                    if (showPlaceholder.length === 0) {
                                                        return <em style={{ fontStyle: 'normal' }}>Select Image</em>;
                                                    }
                                                    return showPlaceholder;
                                                }}
                                            >
                                                {imageDropdownList && imageDropdownList.length > 0 && imageDropdownList.map((item) => (
                                                    <MenuItem value={item} key={item.id}>{item.file_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {selectedUnprocessedImage && selectedUnprocessedImage.length > 0 &&  <div className='wrapsec2'>
                                        <Typography variant='h6' className='headtxtcss fntb14'>Image Description:</Typography>
                                        <Typography variant='p'>{unprocessedImageDescription}</Typography>
                                        <Typography variant='h6' className='headtxtcss fntb14'>Image Tag(s):</Typography>
                                        <Typography variant='p'>{unprocessedImageTag}</Typography>
                                        <Typography variant='h6' className='headtxtcss fntb14'>Image Size:</Typography>
                                        <Typography variant='p'>
                                            {unprocessedImageSize.height} mm (H) x {unprocessedImageSize.width} mm (W)
                                        </Typography>
                                    </div>}
                                    {/*<div className='wrapsec2'>
                                        {selectedUnprocessedImage && selectedUnprocessedImage.length > 0 && <Typography variant='h6' className='headtxtcss fntb14'>Image:</Typography>}
                                        {imageLoading && <p style={{ fontWeight: 'bold' }}>Please wait... Image is loading...</p>}
                                        <div id="diz_openSeaDragon" className={selectedUnprocessedImage && selectedUnprocessedImage.length > 0 ? 'wh200' : ''}></div>
                                    </div>*/}
                                    <div style={{ clear: 'both' }}></div>
                                </Box>
                            </AccordionDetails>
                        </div>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccordionMenu === 'panel3'}
                        onChange={(e, expanded) => setExpandedAccordionMenu(expanded ? 'panel3' : false)}
                    >
                        <AccordionSummary className='accbg'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography className='headcss'>
                                <MemoryOutlinedIcon className='icon' />
                                Process Images
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component="fieldset" className='fieldset'
                                noValidate
                                autoComplete="off"
                            >
                                <Box className='legend' component="legend">Algorithm Selection</Box>
                                <div className='wrapsec2'>
                                    <Typography className='w100 mt10 fntb14'>
                                        Modalities :
                                    </Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel className='pd0' checked={modalityRadioButton === "vIHC"} value="vIHC" control={<Radio className='rdo_btn' onChange={(e) => handleModalitySelection(e)} />} label="vIHC" />
                                        <FormControlLabel className='pd0' checked={modalityRadioButton === "vMultiplex"} disabled={multipleStainsSelected} value="vMultiplex" control={<Radio className='rdo_btn' onChange={(e) => handleModalitySelection(e)} />} label="vMULTIPLEX" />
                                        <FormControlLabel className='pd0' checked={modalityRadioButton === "vFlow"} value="vFlow" control={<Radio className='rdo_btn' onChange={(e) => handleModalitySelection(e)} />} label="FLOW" />
                                    </RadioGroup>
                                </div>
                                <div className='wrapsec2'>
                                    <Typography className='w100 mt10 fntb14'>
                                        Stain List :
                                    </Typography>

                                    <ReactSelect className='drp'
                                        type="checkbox"
                                        isMulti
                                        onChange={handleStainList}
                                        options={stainColourOptions}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{stainOptionComponent}}
                                        placeholder={'Select Stain'}
                                        styles={{
                                            placeholder: (base) => ({
                                              ...base,
                                              fontSize: '1.347em',
                                              color: stainColourOptions[2].color,
                                              fontWeight: 400,
                                            }),

                                          }}
                                        value={dropdownDisplayedStainList}
                                    />
                                </div>
                                <div className='wrapsec2'>
                                    <Typography className='w100 mt10 fntb14'>
                                        Image :
                                    </Typography>
                                        <FormControl className='selectbox'>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={selectedImageToProcessName}
                                                onChange={(data) => handleImageToProcessSelection(data)}
                                                className='wh100'
                                                displayEmpty
                                                renderValue={(showPlaceholder) => {
                                                    if (showPlaceholder.length === 0) {
                                                        return <em style={{ fontStyle: 'normal' }}>Select Image</em>;
                                                    }
                                                    return showPlaceholder;
                                                }}
                                            >
                                                {imageDropdownList && imageDropdownList.length > 0 && imageDropdownList.map((item) => (
                                                    <MenuItem value={item} key={item.id}>{item.file_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                </div>
                                <div className='wrapsec2'>
                                    <Typography className='w100 mt10 fntb14'>
                                        Algorithm :
                                    </Typography>
                                    <FormControl className='selectbox'>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={dropdownDisplayedAlgorithmName}
                                                onChange={(e) => handleAlgorithmSelection(e)}
                                                className='wh100'
                                                displayEmpty
                                                renderValue={(dropdownDisplayedAlgorithmName) => {
                                                    if (dropdownDisplayedAlgorithmName.length === 0) {
                                                        return <em style={{ fontStyle: 'normal' }}>Select Algorithm</em>;
                                                    }
                                                    return dropdownDisplayedAlgorithmName;
                                                }}
                                            >
                                                {unusedAlgoVar2 && unusedAlgoVar2.length && unusedAlgoVar2.map((item) => (
                                                    <MenuItem value={item} key={item.id}>{item.algo_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Button className='btnprog mt10' variant="contained" onClick={() => {
                                            handleInferenceUpload()
                                        }}>Process Image</Button>
                                </div>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccordionMenu === 'panel4'}
                        onChange={(e, expanded) => setExpandedAccordionMenu(expanded ? 'panel4' : false)}
                    >
                        <AccordionSummary className='accbg'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className='headcss'>
                                <SlideshowOutlinedIcon className='icon' />
                                Slide Gallery
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component="fieldset" className='fieldset slide'
                                noValidate
                                autoComplete="off"
                            >
                                <Box className='legend' component="legend">Slide Gallery</Box>
                                <div className='wrapsec2'>
                                <Typography className='w100 mt10 fntb14'>
                                    Image :
                                </Typography>
                                <div className='wdg100'>
                                    <FormControl className='mlr'>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={selectedProcessedImageName}
                                            onChange={(data) => handleProcessedImageSelection(data)}
                                            displayEmpty
                                            className='wh100'
                                            renderValue={(selectedProcessedImageName) => {
                                                if (selectedProcessedImageName.length === 0) {
                                                    return <em style={{ fontStyle: 'normal' }}>Select Processed Image</em>;
                                                }
                                                return selectedProcessedImageName;
                                            }}
                                        >
                                            {dropdownDisplayedProcessedImages && dropdownDisplayedProcessedImages.length > 0 && dropdownDisplayedProcessedImages.map((item) => (
                                                <MenuItem value={item} key={item.cancer_sample_upload_id}>{item.sample_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                </div>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item justifyContent="center" xs={8} sx={{ minHeight: '100%' }}>
                    <Box className="imgviewwrap">
                        {selectedProcessedImageName.length === 0 && <h1 className='imgview'>
                        <img src={imgview} alt="Image Viewer"/> 
                        </h1>}
                        <ImageViewer selectedProcessedImage={selectedProcessedImage}/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dashboard;