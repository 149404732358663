/*
TODO tomorrow
cache images (more specifically unprocessed images, unsure if processed needs it)
fix loading text
renaming/commenting/formatting code
better formating, maybe an overlay? or side bar?
processed image data
remove DZI mentions (new algorithms don't use DZI)
temp coment
*/

import React, { useEffect, useRef, useState} from 'react';
import OpenSeadragon from 'openseadragon';

const ImageViewer = ({ selectedProcessedImage }) => {
    const [showPrediction, setShowPrediction] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const viewerRef = useRef(null);

    useEffect(() => {
        if (selectedProcessedImage) {
            console.log(selectedProcessedImage);
            setIsLoading(true);

            const { uri, stain_list, sample_name, v_ihc, v_multiplex } = selectedProcessedImage;
            let imageUrl;
            const multiplexString= v_multiplex ? "vMultiplex" : "vIHC";

            if (showPrediction) { // TODO: make this so that multiplex works (it won't have a stain_list)
                imageUrl = `${uri}/${multiplexString}-${stain_list}/${sample_name.split('.').slice(0, -1).join('.')}.dzi`; // probably won't work with multiple stains, fix this 
            } else {
                imageUrl = `${uri.split('/').slice(0, -1).join('/')}/${sample_name}`; // double check if this works
            }

            if(viewerRef.current) {
                viewerRef.current.destroy();
                viewerRef.current = null;
            }

            const viewerElement = document.getElementById('openseadragon-viewer');
            if (viewerElement) {
                viewerRef.current = OpenSeadragon({
                    id: 'openseadragon-viewer',
                    prefixUrl: 'openseadragon-images/',
                    tileSources: showPrediction ? imageUrl : undefined ,
                    animationTime: 0.5,
                    blendTime: 0.1,
                    minZoomLevel: 1,
                    minZoomImageRatio: 1,
                    visibilityRatio: 1,
                    zoomPerScroll: 2,
                    constrainDuringPan: true,
                    showHomeControl: false,
                    showZoomControl: false,
                    showNavigationControl: false,
                    showSequenceControl: false,
                    showNavigator: true,
                    navigatorPosition: 'TOP_RIGHT',
                    navigatorSizeRatio: 0.2,
                    immediateRender: true, // might remove
                    timeout: 600000,
                });

                if (showPrediction) {
                    viewerRef.current.addHandler('open', () => {
                        setIsLoading(false);
                    });
                } else {
                    if (window.GeoTIFFTileSource && (!viewerRef.current.tileSources || viewerRef.current.tileSources.length === 0)) {
                        window.GeoTIFFTileSource(OpenSeadragon);
                        let tiffTileSources = OpenSeadragon.GeoTIFFTileSource.getAllTileSources(imageUrl, { logLatency: true });
                        tiffTileSources.then((ts) => {
                            viewerRef.current.open(ts);
                            viewerRef.current.addHandler('tile-drawn', () => {
                                setIsLoading(false);
                            });
                        });
                    } else {
                        console.error("GeoTIFFTileSource not loaded or available");
                        setIsLoading(false);
                    }
                }
            }
        }
    }, [selectedProcessedImage, showPrediction]);

    const handleToggle = () => {
        setShowPrediction((prev) => !prev);
    };

    const handleDownload = async () => {
        const { uri, sample_name } = selectedProcessedImage;
        const link = document.createElement('a');
        if (showPrediction) {
            link.href = `${uri.split('/stained/')[0]}/unstained/${sample_name}`; // change to tiff for processed image in stained folder 
        } else {
            link.href = `${uri.split('/stained/')[0]}/unstained/${sample_name}`;
        }
        link.download = sample_name;
        link.click();
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div id='openseadragon-viewer' style={{ height: 'calc(100vh - 66px)', width: 'calc(100vh - 66px)' }}></div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '20px',
                width: 'calc(100vw - 33vw - (-66px + 16px + 20px + 100vh))'
            }}>
                <button 
                    onClick={handleDownload} 
                    disabled={isLoading} 
                    style={{ 
                        padding: '10px 20px', 
                        fontSize: '16px', 
                        fontWeight: 'bold', 
                        backgroundColor: isLoading ? '#ccc' : '#78909c', 
                        color: 'white', 
                        border: 'none', 
                        borderRadius: '5px', 
                        cursor: isLoading ? 'not-allowed' : 'pointer', 
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        marginBottom: '20px'
                    }}
                >
                    Download Image
                </button>
                <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <input 
                        type="checkbox" 
                        checked={showPrediction} 
                        onChange={handleToggle} 
                        disabled={isLoading} 
                        style={{ marginRight: '10px', transform: 'scale(1.2)', cursor: 'pointer' }}
                    />
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Display Predicition</span>
                </label>
            </div>
        </div>
    );
};

export default ImageViewer;