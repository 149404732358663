/**
 * @function LoginReducer [Set the updated state for login module]
 * @param {object} state
 * @param {object} action
 */
 export function saveImagesDataReducer(state = {}, action) {
    switch (action.type) {
    case 'SAVE_IMAGE_SUCCESS':
        return { status: 'success', data: action.payload };
    case 'SAVE_IMAGE_ERROR':
        return { status: 'error', data: action.payload };
    case 'SAVE_IMAGE__RESET':
        return { status: 'reset', data: action.payload };

    default:
        return { ...state };
    }
}

export function fetchImagesDataReducer(state = {}, action) {
    switch (action.type) {
    case 'FETCH_IMAGE_SUCCESS':
        return { status: 'SUCCESS', data: action.payload };
    case 'FETCH_IMAGE_ERROR':
        return { status: 'error', data: action.payload };

    default:
        return { ...state };
    }
}

export function processImageApiDataReducer(state = {}, action) {
    switch (action.type) {
    case 'PROCESS_IMAGE_SUCCESS':
        return { status: 'success', data: action.payload };
    case 'PROCESS_IMAGE_ERROR':
        return { status: 'error', data: action.payload };
    case 'PROCESS_IMAGE__RESET':
        return { status: 'reset', data: action.payload };

    default:
        return { ...state };
    }
}

export function processedDataReducer(state = {}, action) {
    switch (action.type) {
    case 'PROCESSED_IMAGE_SUCCESS':
        return { status: 'success', data: action.payload };
    case 'PROCESSED_IMAGE_ERROR':
        return { status: 'error', data: action.payload };
    case 'PROCESSED_IMAGE__RESET':
        return { status: 'reset', data: action.payload };

    default:
        return { ...state };
    }
}

export function getClientsDataReducer(state = {}, action) {
    switch (action.type) {
    case 'GET_CLIENTS_SUCCESS':
        return { status: 'success', data: action.payload };
    case 'GET_CLIENTS_ERROR':
        return { status: 'error', data: action.payload };
    case 'GET_CLIENTS__RESET':
        return { status: 'reset', data: action.payload };

    default:
        return { ...state };
    }
}

export function addTenantsDataReducer(state = {}, action) {
    switch (action.type) {
    case 'ADD_TENANTS_SUCCESS':
        return { status: 'success', data: action.payload };
    case 'ADD_TENANTS_ERROR':
        return { status: 'error', data: action.payload };
    case 'ADD_TENANTS_RESET':
        return { status: 'reset', data: action.payload };

    default:
        return { ...state };
    }
}

export function getTenantsDetailsDataReducer(state = {}, action) {
    switch (action.type) {
    case 'GET_TENANTS_DETAILS_SUCCESS':
        return { status: 'success', data: action.payload };
    case 'GET_TENANTS_DETAILS_ERROR':
        return { status: 'error', data: action.payload };
    case 'GET_TENANTS_DETAILS_RESET':
        return { status: 'reset', data: action.payload };

    default:
        return { ...state };
    }
}

export function getPendingUsersReducer(state = {}, action) {
    switch (action.type) {
    case 'GET_PENDING_USERS_SUCCESS':
        return { status: 'success', data: action.payload };
    case 'GET_PENDING_USERS_ERROR':
        return { status: 'error', data: action.payload };
    case 'GET_PENDING_USERS_RESET':
        return { status: 'reset', data: action.payload };

    default:
        return { ...state };
    }
}

export function approvePendingUsersReducer(state = {}, action) {
    switch (action.type) {
    case 'APPROVE_PENDING_USERS_SUCCESS':
        return { status: 'success', data: action.payload };
    case 'APPROVE_PENDING_USERS_ERROR':
        return { status: 'error', data: action.payload };
    case 'APPROVE_PENDING_USERS_RESET':
        return { status: 'reset', data: action.payload };

    default:
        return { ...state };
    }
}

export function getAlgorithmsReducer(state = {}, action) {
    switch (action.type) {
    case 'GET_ALGORITHM_LIST_SUCCESS':
        return { status: 'success', data: action.payload };
    case 'GET_ALGORITHM_LIST_ERROR':
        return { status: 'error', data: action.payload };
    case 'GET_ALGORITHM_LIST_RESET':
        return { status: 'reset', data: action.payload };

    default:
        return { ...state };
    }
}